<template>
  <div ref="searchSchoolDom">
    <el-autocomplete
      class="edit-school"
      popper-class="edit-school-select"
      v-model="editWholeSchoolName"
      :fetch-suggestions="querySchoolSearch"
      :placeholder="inputPlaceholder"
      value-key="schoolName"
      :clearable="true"
      :trigger-on-focus="true"
      ref="inputWholeSchoolName"
      :disabled="disableEdit"
      :popper-append-to-body="false"
      @select="handleSchoolSelect"
      @focus="handleSchoolFocus"
      @blur="handleSchoolBlur"
      @change="handleSchoolChange"
      @input="handleSchoolInput"
    >
      <template slot-scope="{ item }">
        <div class="select-school-item" v-show="!item.type">
          <span class="select-school-item-name"
            >{{ item.schoolName }}
            <span v-show="item.schoolType == 1">(小学)</span>
            <span v-show="item.schoolType == 2">(中学)</span>
            <span v-show="item.schoolType == 3">(小学和中学)</span>
          </span>
          <span class="select-school-item-edit" @click.stop="handleEditSchoolInfo(item)"
            >添加简称</span
          >
        </div>
        <div
          class="select-school-no-data"
          v-show="
            !searchSchoolLoading &&
            (item.type == 'no-data' ||
              item.type == 'load-more' ||
              item.type == 'load-end')
          "
          @click.stop=""
        >
          {{ item.schoolName }}
        </div>
      </template>
    </el-autocomplete>
    <addSchoolKeywordDialog
      :addSchoolKeywordParams="addSchoolKeywordParams"
      @dialogDataChange="handleAddSchoolKeywordDataChange"
    ></addSchoolKeywordDialog>
  </div>
</template>
<script>
import { apiGetSchoolList } from "@/api/sales";
import addSchoolKeywordDialog from "@/components/sales/addSchoolKeywordDialog";
export default {
  data() {
    return {
      editWholeSchoolName: null,
      selectWholeSchoolName: null,
      searchSchoolLoading: false,
      searchSchoolInfo: {
        loadingMore: false,
        noMoreData: false,
        schoolList: [],
        selectSchoolName: null,
        selectSchoolId: null,
        query: {
          pageNum: 1,
          pageSize: 10,
          keywords: "",
        },
      },
      selectWrapEl: null,
      selectListEl: null,
      observer: null,
      addSchoolKeywordParams: {
        type: "add",
        id: null,
        show: false,
      },
    };
  },
  props: {
    inputValue: {
      type: String,
    },
    inputPlaceholder: {
      type: String,
    },
    disableEdit: {
      type: Boolean,
    },
  },
  watch: {
    inputValue() {
      this.init();
    },
    disableEdit() {
      console.log("disableEdit: ", this.disableEdit);
    },
  },
  components: { addSchoolKeywordDialog },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    /* 初始化 */
    init() {
      this.selectWholeSchoolName = this.inputValue;
      this.editWholeSchoolName = this.inputValue;
    },
    /* 加载学校 */
    async getSchoolList(cb) {
      try {
        this.searchSchoolLoading = true;
        const { records, total } = await apiGetSchoolList(this.searchSchoolInfo.query);
        this.searchSchoolLoading = false;
        if (cb) {
          this.searchSchoolInfo.schoolList = records;
          if (this.searchSchoolInfo.schoolList.length == 0) {
            this.searchSchoolInfo.schoolList = [
              {
                schoolName: "暂无数据",
                type: "no-data",
              },
            ];
          }
          cb(this.searchSchoolInfo.schoolList);
        } else {
          const endSchoolType = this.searchSchoolInfo.schoolList[
            this.searchSchoolInfo.schoolList.length - 1
          ].type;
          if (endSchoolType == "load-more" || endSchoolType == "load-end") {
            console.log("进入 pop");
            this.$refs.inputWholeSchoolName.$data.suggestions.pop();
          }
          /* this.searchSchoolInfo.schoolList = [
            ...this.searchSchoolInfo.schoolList,
            ...records,
          ]; */
          this.$refs.inputWholeSchoolName.$data.suggestions.push(...records);
        }
        //console.log("this.searchSchoolInfo.schoolList: ", this.searchSchoolInfo.schoolList)
        if (
          this.searchSchoolInfo.query.pageNum * this.searchSchoolInfo.query.pageSize >=
          total
        ) {
          this.searchSchoolInfo.noMoreData = true;
          if (this.searchSchoolInfo.query.pageNum > 1) {
            this.$refs.inputWholeSchoolName.$data.suggestions.push({
              schoolName: "没有更多数据",
              type: "no-data",
            });
          }
        } else {
          if (this.searchSchoolInfo.query.pageNum > 1) {
            this.$refs.inputWholeSchoolName.$data.suggestions.push({
              schoolName: "加载中...",
              type: "load-more",
            });
          }
        }
        this.searchSchoolInfo.loadingMore = false;
      } catch (err) {
        let msg = err.msg ? err.msg : "查询学校发生异常！";
        this.msgError(msg);
        console.error("getSchoolList err: ", err);
        this.searchSchoolInfo.loadingMore = false;
      }
    },
    /* 加载更多学校 */
    getMoreSchoolList() {
      if (!this.searchSchoolInfo.loadingMore && !this.searchSchoolInfo.noMoreData) {
        //console.log("进入了");
        this.searchSchoolInfo.loadingMore = true;
        this.searchSchoolInfo.query.pageNum++;
        this.getSchoolList();
      }
    },
    /* 选择学校 */
    handleSchoolSelect() {
      console.log("handleSchoolSelect editWholeSchoolName: ", this.editWholeSchoolName);
      this.selectWholeSchoolName = this.editWholeSchoolName;
    },
    /* 学校input */
    handleSchoolInput() {
      console.log("handleSchoolInput");
    },
    /* 学校改变 */
    handleSchoolChange() {
      console.log("handleSchoolChange editWholeSchoolName: ", this.editWholeSchoolName);
    },
    /* 学校blur */
    handleSchoolBlur() {
      this.selectWrapEl.removeEventListener("scroll", this.handleScroll);
      setTimeout(() => {
        console.log("handleSchoolBlur editWholeSchoolName: ", this.editWholeSchoolName);
        if (!this.editWholeSchoolName) {
          this.selectWholeSchoolName = "";
        } else if (this.editWholeSchoolName != this.selectWholeSchoolName) {
          // 输入框内容存在但不等于改选择的内容，则恢复之前的
          this.editWholeSchoolName = this.selectWholeSchoolName;
        }
        if (this.selectWholeSchoolName != this.inputValue) {
          this.$emit("change", this.selectWholeSchoolName);
        }
      }, 500);
    },
    /* 处理滚动 */
    handleScroll() {
      //console.log("滚动了！");
      if (
        this.selectWrapEl.scrollTop + this.selectWrapEl.clientHeight >=
        this.selectListEl.clientHeight - 10
      ) {
        //console.log("滚动到底部了！");
        if (
          this.searchSchoolInfo.schoolList.length >= this.searchSchoolInfo.query.pageSize
        ) {
          //console.log("滚动到底部了，开始加载更多！");
          this.getMoreSchoolList();
        }
      }
    },
    /* 学校focus */
    handleSchoolFocus() {
      console.log("handleSchoolFocus editWholeSchoolName: ", this.editWholeSchoolName);
      this.$nextTick(() => {
        this.selectWrapEl = document.querySelector(
          ".edit-school-select .el-autocomplete-suggestion__wrap"
        );
        this.selectListEl = document.querySelector(
          ".edit-school-select .el-autocomplete-suggestion__list"
        );
        this.selectWrapEl.addEventListener("scroll", this.handleScroll);
      });
    },
    /* 编辑学校信息 */
    handleEditSchoolInfo(item) {
      console.log("handleEditSchoolInfo item: ", item);
      this.addSchoolKeywordParams = {
        show: true,
        id: item.id,
        type: "add",
      };
    },
    /* 编辑学校信息发生改变 */
    handleAddSchoolKeywordDataChange({ type }) {
      if (type == "close") {
        this.addSchoolKeywordParams.show = false;
      }
    },
    /* 查询学校 */
    async querySchoolSearch(queryString, cb) {
      //console.log("queryString: ", queryString);
      this.searchSchoolInfo.query.pageNum = 1;
      this.searchSchoolInfo.query.keywords = queryString;
      this.searchSchoolInfo.noMoreData = false;
      this.getSchoolList(cb);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-school-item {
  padding: 0 20px;
  width: 100%;
  .select-school-item-name {
    float: left;
    text-align: left;
  }
  .select-school-item-edit {
    margin-left: 20px;
    float: right;
    color: #409eff;
  }
}
.select-school-no-data {
  padding: 0 20px;
  cursor: default;
  color: #c0c4cc;
  text-align: center;
  &:hover {
    background: #ffffff;
  }
}
.search-end {
  height: 2px;
}
.uploadTip {
  line-height: 30px;
  color: #909399;
  text-align: center;
}
</style>
