import { render, staticRenderFns } from "./searchSelectv2.vue?vue&type=template&id=ada51fa6&scoped=true&"
import script from "./searchSelectv2.vue?vue&type=script&lang=js&"
export * from "./searchSelectv2.vue?vue&type=script&lang=js&"
import style0 from "./searchSelectv2.vue?vue&type=style&index=0&id=ada51fa6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ada51fa6",
  null
  
)

export default component.exports